<template>
  <b-modal ref="department-modal" hide-footer title="Thông tin phòng ban">
    <div class="w-50">
      <b-form-group label="Phòng ban cha:">
        <b-form-input
          v-if="currentDepartmentId"
          disabled
          v-model="currentDepartmentName"
        ></b-form-input>
        <treeselect
          v-else
          :options="listItem"
          :multiple="false"
          placeholder="Chọn phòng ban cha"
          :match-keys="['id', 'label']"
          v-model="departmentModel.parentId"
          :clearable="true"
          :show-count="true"
          openDirection="bottom"
        >
        </treeselect>
      </b-form-group>
    </div>
    <div class="w-50">
      <b-form-group class="required-control">
        <label>Tên:</label>
        <b-form-input
          v-model.trim="departmentModel.name"
          placeholder="Nhập tên"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback
          >Yêu cầu nhập tên phòng ban</b-form-invalid-feedback
        >
      </b-form-group>
    </div>

    <b-button
      style="fontweight: 600; width: 70px"
      variant="primary"
      size="sm"
      v-if="checkPermission('DEPARTMENT_INSERT')"
      @click="onSubmit()"
      >Lưu</b-button
    >
    <b-button
      style="margin-left: 10px; font-weight: 600; width: 70px"
      variant="secondary"
      size="sm"
      @click="hideModal()"
      >Hủy</b-button
    >
  </b-modal>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import localData from '@/utils/saveDataToLocal';
import { validationMixin } from 'vuelidate';
import { makeToastFaile, makeToastSuccess, cloneDeep } from '@/utils/common';
import ApiService from '@/core/services/api.service';

export default {
  mixins: [validationMixin],
  data() {
    return {
      listItem: [],
      parentDepartment: null,
      currentDepartmentName: '',
      currentDepartmentId: null,
      departmentModel: {
        id: null,
        name: '',
        parentId: null,
      },
    };
  },
  components: {
    Treeselect,
  },
  methods: {
    showModal(model, items) {
      this.departmentModel = cloneDeep(model);
      this.listItem = cloneDeep(items);
      this.$refs['department-modal'].show();
    },
    hideModal() {
      this.$refs['department-modal'].hide();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSubmit() {
      const payload = cloneDeep(this.departmentModel);
      if (!payload.name) {
        return makeToastFaile('Vui lòng nhập tên cấu hình phụ cấp!');
      }
      if (!this.departmentModel.id) {
        return this.onCreate(payload);
      }
      this.onUpdate(payload);
    },
    onCreate(payload) {
      ApiService.post('departments', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.hideModal();
            this.$emit('on-fetch-data');
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    onUpdate(payload) {
      const data = {
        ...payload,
        id: this.departmentModel.id,
      };
      ApiService.put('departments', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.hideModal();
            this.$emit('on-fetch-data');
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
  },
};
</script>