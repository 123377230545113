<template>
  <div>
    <KTCodePreview v-bind:title="' '">
      <template v-slot:toolbar>
        <div class="row">
          <b-button
            v-show="checkPermission('DEPARTMENT_INSERT')"
            variant="primary"
            size="sm"
            @click="showModalAdd"
            class="font-weight-bolder"
          >
            <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
          </b-button>
        </div>
      </template>
      <template v-slot:preview>
        <b-col cols="8" class="mb-5">
          <div class="row">
            <b-input
              placeholder="Tìm kiếm theo tên"
              v-model="searchName"
              class="col-md-4 mr-4"
              v-on:keyup.enter="fetchData()"
              size="sm"
            />
            <b-col md="1">
              <b-button
                size="sm"
                variant="primary"
                style="width: 70px"
                @click="fetchData()"
                >Lọc</b-button
              >
            </b-col>
          </div>
        </b-col>
        <b-table
          :items="listItem"
          :fields="fields"
          class="col-md-12"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
          </template>
          <template v-slot:cell(stt)="row">
            <span v-text="row.item.stt"></span>
          </template>
          <template v-slot:cell(name)="row">
            <!-- list item lv 0 -->
            <li class="list-level-0" v-b-toggle:[`item-level1-${row.item.id}`]>
              <p v-html="highlightMatchesName(row.item.name)"></p>
              <span class="categories_quantity" v-if="row.item.children.length">
                ({{ row.item.children.length }})
              </span>
              <i
                v-if="row.item.children.length > 0"
                class="fa fa-angle-down arrow-level-0"
              ></i>
            </li>
            <b-collapse
              :ref="`collapse-${row.item.id}`"
              :id="`item-level1-${row.item.id}`"
            >
              <ListItemCate
                v-for="itemLv1 in row.item.children"
                :key="itemLv1.id"
                :item="itemLv1"
                @editItem="editItem"
                @addChildCate="addChildCate"
                @showDeleteAlert="showDeleteAlert"
                :searchName="searchName"
                ref="collapseAll"
              >
              </ListItemCate>
            </b-collapse>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-settings pr-0"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="showModalUpdate(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 10px" class="flaticon2-box-1"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showModalUpdate(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="addChildCate(row.item)"
                  v-show="checkPermission('CATE_INSERT')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #1bc5bc !important"
                      class="fas fa-plus"
                    ></i>
                    &nbsp; Thêm danh mục con
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-show="checkPermission('DEPARTMENT_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ listItem.length }}
            </p>
          </b-col>
        </b-row>
        <DepartmentModal
          ref="department-modal"
          v-on:on-fetch-data="fetchData()"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.icon {
  padding-top: 7px;
}
.ml-10 {
  margin-left: 5px;
}
.ml-20 {
  margin-left: 10px;
}
.ml-30 {
  margin-left: 15px;
}
.ml-40 {
  margin-left: 20px;
}
.ml-50 {
  margin-left: 25px;
}

.icon:hover {
  background-color: #90c6fc;
}

.container-fluid {
  height: 100%;
  display: table;
  width: 100%;
  padding: 0;
}

.row-fluid {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.centering {
  float: none;
  margin: 0 auto;
}

.dropdown-menu li {
  line-height: 15px;
}
.arrow-level-0 {
  position: absolute;
  color: #464e5f;
  right: 30px;
  font-size: 20px;
  top: 5px;
}

.action-level-1 {
  position: absolute;
  top: 1px;
  right: 17px;
}

.action-level-1 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-1 .arrow-down {
  margin-right: 10px;
}

.action-level-2 {
  position: absolute;
  top: 1px;
  right: 17px;
}

.action-level-2 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-2 .arrow-down {
  margin-right: 10px;
}

.action-level-3 {
  position: absolute;
  top: 1px;
  right: 17px;
}

.action-level-3 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-3 .arrow-down {
  margin-right: 10px;
}

.list-level-0 {
  position: relative;
  list-style-type: none;
  font-weight: bolder;
  color: #464e5f;
}

.categories_quantity {
  position: absolute;
  top: 6px;
  right: 50px;
  font-weight: lighter;
}

.list-level-1 {
  list-style-type: decimal;
  margin-left: 10px;
  line-height: 40px;
  position: relative;
}

.list-level-1 .arrow-down-level-1 {
  position: absolute;
  color: #464e5f;
  right: 66px;
  top: 14px;
}

.list-level-2 {
  list-style-type: circle;
  margin-left: 12px;
  line-height: 35px;
  position: relative;
}

.list-level-2 .arrow-down-level-2 {
  position: absolute;
  color: #464e5f;
  right: 66px;
  top: 14px;
}

.list-level-3 {
  list-style-type: square;
  margin-left: 24px;
  line-height: 35px;
  position: relative;
}
.collapsing {
  transition: none !important;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '@/utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { required } from 'vuelidate/lib/validators';
import ListItemCate from '@/view/components/ListItemCate.vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { removeAccents } from '@/utils/common';
import DepartmentModal from '../../components/departments/DepartmentModal';

export default {
  mixins: [validationMixin],
  data() {
    return {
      searchName: '',
      show: false,
      fields: [
        {
          key: 'stt',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          tdClass: 'nameCateClass',
          thStyle: { fontWeight: 600, color: '#181c32', width: '45%' },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        { key: 'actions', label: '', width: '5%' },
      ],
      listItem: [],
      listCate: [],
      selectedParent: null,
      name: '',
      code: '',
      valid: true,
      cateId: 0,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true,
      currentInternalCateId: null,
      currentInternalCateName: null,
      currentInternalCateCode: null,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    ListItemCate,
    DepartmentModal,
  },
  validations: {
    name: { required },
    code: { required },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách bộ phận/ phòng ban' },
    ]);
    this.show = false;
  },
  methods: {
    highlightMatchesName(text = '') {
      const searchTerm = this.searchName ? this.searchName.trim() : '';

      const matchExists = removeAccents(text)
        .toLowerCase()
        .includes(removeAccents(searchTerm).toLowerCase());

      if (!matchExists) return text;

      const regex = new RegExp(searchTerm, 'ig');
      return text.replace(
        regex,
        (matchedText) =>
          `<strong style="color: #FF2400">${matchedText}</strong>`,
      );
    },
    fetchData: async function () {
      const param = {
        name: this.searchName,
      };
      const paramQuery = {
        params: param,
      };
      this.onLoading = true;
      ApiService.query('departments/', paramQuery)
        .then(({ data }) => {
          this.listItem = data.data.map((element, index) => {
            return {
              ...element,
              label: element.name,
              stt: index + 1,
              children: [],
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('CATE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('CATE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    deleteItem: async function (item) {
      const id = item.id;
      ApiService.post(`productExternalCategory/delete/${id}`).then(
        ({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.fetchData();
            this.show = false;
          } else {
            makeToastFaile(data.message);
          }
        },
      );
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa danh mục!',
        text: 'Bạn có chắc muốn xóa danh mục này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    addChildCate: function (item) {
      this.showModalAdd();
      this.selectedParent = item.id;
      this.currentInternalCateId = item.id;
      this.currentInternalCateName = item.name;
      this.currentInternalCateCode = item.code;
    },
    showModalAdd() {
      const departmentModel = {
        id: null,
        name: '',
        parentId: null,
      };
      this.$refs['department-modal'].showModal(departmentModel, this.listItem);
    },
    showModalUpdate(departmentModel) {
      this.$refs['department-modal'].showModal(departmentModel, this.listItem);
    },
  },
};
</script>
